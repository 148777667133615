import React, { SyntheticEvent, useState, useRef } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { AutocompleteArrayInput, NotificationType, required, useNotify } from 'react-admin'
import axios, { AxiosError } from 'axios'
import { stripCategoryAndIQVIAPrefix } from 'company/showTabs/PortalProperties'
import { searchTaxonomy } from 'api/company'

export interface TaxonomySearchOptionRecord {
  id: string,
  label: string
}

interface TaxonomyMultiSearchProps {
  defaultOptions?: TaxonomySearchOptionRecord [],
  defaultValues?: TaxonomySearchOptionRecord [],
  onChange?: (value: string[]) => void
}

export const convertTaxonomyKeysToOptions = (taxonomyKeys: string[]): TaxonomySearchOptionRecord[] =>
  taxonomyKeys ? taxonomyKeys.map(( value ) => ({ id: value, label: stripCategoryAndIQVIAPrefix(value) })) : []

const findTaxonomyKeys = async (search: string, sourceRef: React.MutableRefObject<AbortController | undefined> ): Promise<string[]> => {
  const response = await searchTaxonomy(search, sourceRef)
  return response.results[0]?.data
    .flatMap((g: { graph: { nodes: any } }) => g.graph.nodes)
    .map((n: { properties: { title: any } }) => n.properties.title)
    .filter((t: string) => t.toLowerCase() !== 'Category:Main_topic_classifications'.toLowerCase())
    .filter((t: string) => t.toLowerCase().includes(search.toLowerCase()))
}

const catchError = (
  error: unknown, 
  notify: (message: string | React.ReactNode, options?: NotificationOptions & { type?: NotificationType }) => void
) => {
  if (!(axios.isAxiosError(error) && error.code == AxiosError.ERR_CANCELED)){
    notify('Something went wrong during searching for taxonomy.', { type: 'error' })
  }
}

export function TaxonomyMultiSearch ({ defaultOptions, defaultValues, onChange }: TaxonomyMultiSearchProps) {
  const [searchDelay, setSearchDelay] = useState<ReturnType<typeof setTimeout> | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [taxonomyOptions, setTaxonomyOptions] = useState<TaxonomySearchOptionRecord[]>(defaultOptions || [])
  const [selectedValues, setSelectedValues] = useState<TaxonomySearchOptionRecord[] | undefined>(defaultValues || [])
  const notify = useNotify()
  const sourceRef = useRef<AbortController>()

  const handleClose = () => {
    setTaxonomyOptions([])
  }

  const fetchTaxonomy = async (search: string) => {
    setIsLoading(true)
    try {
      if(search !== ''){
        setTaxonomyOptions(selectedValues ? selectedValues : [])
        const findedTaxonomyKeys = await findTaxonomyKeys(search, sourceRef)
        const uniqFindedTaxonomy = [...new Set([...findedTaxonomyKeys, ...(selectedValues ? selectedValues?.map(t => t.id) : []) ])]
        setTaxonomyOptions(convertTaxonomyKeysToOptions(uniqFindedTaxonomy))
      }
    } catch (error) {
      catchError(error, notify)
    }
    setIsLoading(false)
  }

  const handleTextChange = (_: SyntheticEvent<Element, Event>|null, value: string | null) => {
    if (sourceRef && sourceRef.current) sourceRef.current.abort()
    if (searchDelay) {
      clearTimeout(searchDelay)
    }
    setSearchDelay(setTimeout(() => fetchTaxonomy(value || ''), 500))
  }

  const handleOptionChange = (_: SyntheticEvent<Element, Event>|null, value: TaxonomySearchOptionRecord[] | undefined) => {
    setSelectedValues(value)
    onChange && onChange(value ? value.map(tk => tk.id) : [])
    setTaxonomyOptions(value ? value : [])
  }

  return (
    <Autocomplete
      sx={{ marginTop: "15px" }}
      fullWidth
      multiple
      loading={isLoading}
      onClose={handleClose}
      filterSelectedOptions
      limitTags={2}
      onInputChange={handleTextChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={taxonomyOptions}
      onChange={handleOptionChange}
      value={selectedValues}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Taxonomy search"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  )
}

interface TaxonomyMultiSearchInputProps {
  defaultOptions?: TaxonomySearchOptionRecord [],
  defaultValues?: string [],
  onChange?: (value: string[]) => void
}

export const TaxonomyMultiSearchInput = ({ defaultOptions, defaultValues, onChange }: TaxonomyMultiSearchInputProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [searchDelay, setSearchDelay] = useState<ReturnType<typeof setTimeout> | undefined>(undefined)
  const [taxonomyOptions, setTaxonomyOptions] = useState<TaxonomySearchOptionRecord[]>(defaultOptions || [])
  const [selectedValues, setSelectedValues] = useState<any[] >(defaultValues || [])
  const notify = useNotify()
  const sourceRef = useRef<AbortController>()

  const fetchTaxonomyKeysOptions = async (search: string) => {
    setIsLoading(true)
    try {
      if(search !== ''){
        setTaxonomyOptions((convertTaxonomyKeysToOptions(selectedValues)))
        const findedTaxonomyKeys = await findTaxonomyKeys(search, sourceRef)
        const uniqFindedTaxonomy = [...new Set([...findedTaxonomyKeys, ...selectedValues])]
        setTaxonomyOptions(convertTaxonomyKeysToOptions(uniqFindedTaxonomy))
      }
    } catch (error) {
      catchError(error, notify)
    }
    setIsLoading(false)
  }

  const handleChangeFilter = (value: string) => {
    if (sourceRef && sourceRef.current) sourceRef.current.abort()
    if (searchDelay) {
      clearTimeout(searchDelay)
    }
    setSearchDelay(setTimeout(() => fetchTaxonomyKeysOptions(value || ''), 500))
  }

  const handleOptionChange = (value: any) => {
    setSelectedValues(value)
    onChange && onChange(value)
    setTaxonomyOptions((convertTaxonomyKeysToOptions(value)))
  }

  return (
    <AutocompleteArrayInput
      label="Taxonomy keys"
      source="taxonomyKey"
      size='small'
      sx={{ minWidth: '223px' }} 
      validate={[required()]}
      optionText='label'
      choices={taxonomyOptions}
      noOptionsText={<React.Fragment>
        {isLoading ? <CircularProgress color="inherit" size={20} /> : 'No options'}
      </React.Fragment>
      }
      setFilter={handleChangeFilter}
      onChange={handleOptionChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      defaultValue={selectedValues}
    />
  )
}