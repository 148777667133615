import React, { useEffect, useState } from 'react'
import ActionButton from 'components/ActionButton'
import { getMultiLangJobForSingleLangJobId, MultiLangJobDto } from 'api/multilangJob'
import { useRecordContext, useRedirect } from 'ra-core'
import { ExtendedJobRecord } from 'api/jobs'

const OpenMultiLangJob = () => {
  const redirect = useRedirect()
  const record = useRecordContext<ExtendedJobRecord>()
  const [multiLangJob, setMultiLangJob] = useState<MultiLangJobDto>()

  useEffect(() => {
    const checkMultiLangJobExistence = async () => {
      const multiLangJob = await getMultiLangJobForSingleLangJobId(record.id)
      if (multiLangJob !== undefined && multiLangJob.singleLangJobs.length > 1) {
        setMultiLangJob(multiLangJob)
      }
    }
    checkMultiLangJobExistence()
  }, [])

  return <ActionButton disabled={multiLangJob === undefined} onClick={() => redirect(`/multilang-jobs/${multiLangJob?.id}/show`)} >
    All Languages
  </ActionButton>
}

export default OpenMultiLangJob