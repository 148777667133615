import axios from "axios"

export type MultiLangJobDto = {
    id: string,
    singleLangJobs: SingleLangJobDto[],
    projectName: string
}

export type MultiLangJobDetailsDto = {
    id: string,
    singleLangJobs: SingleLangJobDto[],
    projectName: string
}

export type SingleLangJobDto = {
    jobId: string,
    targetLanguage: string,
    status: SingleLangJobStatus
}

export enum SingleLangJobStatus {
    QUOTING = "QUOTING",
    QUOTED = "QUOTED",
    REJECTED = "REJECTED",
    IN_PROGRESS = "IN_PROGRESS",
    FINISHED = "FINISHED",
    CANCELLED = "CANCELLED"
}

export async function getMultiLangJobForSingleLangJobId (singleLangJobId: string): Promise<MultiLangJobDto | undefined> {
  const response = await axios.get(`/portal/api/multilang-jobs`, {
    params: { singleLangJobId }
  })
  return response.data.results[0]
}