import React from 'react'
import { SxProps, Theme, styled } from "@mui/material/styles"
import { FONT_COLOR } from 'job/jobShow/Common'

const StyledButton = styled('button')({
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  border: '1px solid #CCCCCC',
  borderRadius: '4px',
  minWidth: '175px',
  paddingTop: '10px',
  paddingBottom: '10px',
  cursor: 'pointer',

  font: 'normal normal 500 14px/18px Inter',
  color: FONT_COLOR,

  ':not(:disabled):hover': {
    border: `1px solid ${FONT_COLOR}`,
    boxShadow: '1px 1px 3px #00000029',
    textShadow: '0 0.015em #101010,0 -0.02em #101010,0.01em 0 #101010,-0.01em 0 #101010'
  },
  
  ':disabled': {
    backgroundColor: '#F2F2F2',
    color: '#B5B5B5',
    cursor: 'not-allowed'
  }
})

interface ButtonProps {
  onClick?: () => void
  children: React.ReactNode
  disabled?: boolean
  sx?: SxProps<Theme>
}

const CustomButton = ({ onClick, disabled, children, sx }: ButtonProps) => {
  return <StyledButton sx={sx} disabled={disabled} onClick={onClick}>{children}</StyledButton>
}

export default CustomButton
export type { ButtonProps }