import React from 'react'
import { Box, Divider, Typography } from '@mui/material'
import { MultiLangJobDetailsDto } from 'api/multilangJob'
import SideCard from 'components/SideCard'
import Status from 'components/Status'
import { NormText, SM_FONT_LINE_H28 } from 'job/jobShow/Common'
import { Link, Show, useRecordContext, useTranslate } from "react-admin"

const MultiLangLanguagesTable = () => {
  const record = useRecordContext<MultiLangJobDetailsDto>()
  const translate = useTranslate()
  return (
    <Box display={{ md: 'block', lg: 'flex' }} flexDirection='column' gap="35px" flexWrap='wrap'>
      <Typography sx={{ font: 'normal normal bold 26px/31px Inter' }}>
        All Project Languages
      </Typography>
      <SideCard width='450px'>
        <Box padding='25px 20px 25px 35px' display='flex' gap='10px' justifyContent='space-between'>
          <NormText sx={{ width: '66%', font: SM_FONT_LINE_H28 }}><b>Language</b></NormText>
          <NormText sx={{ width: '34%', font: SM_FONT_LINE_H28 }}><b>Status</b></NormText>
    
        </Box>

        <Divider />

        <Box padding='25px 20px 25px 35px' display='flex' gap='10px' justifyContent='space-between'>
          <Box display='flex' flexDirection='column' gap='31px' width='66%' word-wrap='break-word'>   
            {record?.singleLangJobs?.map(( { jobId, targetLanguage } ) => (   
              <NormText key={jobId}><Link to={`/jobs/${jobId}/show`}>{translate(`dict.lang.${targetLanguage}`)}</Link></NormText>
            ))}
          </Box>

          <Box display='flex' flexDirection='column' gap='31px' width='34%'>
            {record?.singleLangJobs?.map(( { jobId, status } ) => (   
              <Status key={jobId} status={status} text={translate(`job.status.${status}`)} />
            ))}    
          </Box>
        </Box>
      </SideCard>
    </Box>
  )
}

const MultiLangTitle = () => {
  const record = useRecordContext<MultiLangJobDetailsDto>()
  return <span>{record?.projectName}</span>
}

const MultiLangJobShow = () => {
  return (
    <Show title={<MultiLangTitle />} component="div" className='user-panel'>
      <MultiLangLanguagesTable />
    </Show>
  )
}

export default MultiLangJobShow