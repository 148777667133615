import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import * as XLSX from "xlsx"
import { Box, Card, CardContent, Checkbox, Grid, Typography } from "@mui/material"
import { Done } from '@mui/icons-material'
import { Title, useTranslate, useNotify, useStore } from "react-admin"

import { GAP, NoSel, NormText } from 'job/jobShow/Common'
import { SelectOption } from 'api/common'
import { CustomSelectInput } from 'common/OutlinedSelectInput'
import { CustomMultiselect } from 'common/OutlinedArrayInput'
import { LanguageAutocomplete } from 'common/LanguageFilter'
import { GatedCommunitySearch, GatedCommunitySearchOptionRecord } from 'common/GatedCommunitySearchFilter'
import { saveFile } from 'job/RCAList'
import CustomButton from 'components/CustomButton'
import RoundedPanel from 'common/RoundedPanel'
import { GatedCommunitySearchRecord } from 'api/gatedCommunity'
import { SubassetsListDashboardButton } from 'subassets/SubAssetsList'
import { convertTaxonomyKeysToOptions, TaxonomyMultiSearch } from './TaxonomyMultiSearch'

const SubAssets = () => {
  const translate = useTranslate()
  const notify = useNotify()

  const fileInputRef = useRef<HTMLInputElement| null>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>()

  const [items, setItems] = useState<Array<Array<string>>>([])
  const [columnNames, setColumnNames] = useState<Array<string>>([])
  const [columnNamesSelectOptions, setColumnNamesSelectOptions] = useState<Array<SelectOption>>([])

  const [langFrom, setLangFrom] = useState<SelectOption>()
  const [langTo, setLangTo] = useState<SelectOption>()
  const [sourceTextIndex, setSourceTextIndex] = useState<number>()
  const [targetTextIndex, setTargetTextIndex] = useState<number>()
  const [commentColumnsIndexes, setCommentColumnsIndexes] = useState<Array<string>>([])
  const [taxonomyKeys, setTaxonomyKeys] = useStore<string[]>('taxonomyKeys', [])
  const [gatedCommunities, setGatedCommunities] = useState<GatedCommunitySearchOptionRecord[] | undefined>([])
  const [isDeleteOldBatchSubAssets, setIsDeleteOldBatchSubAssets] = useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [gatedCommunitiesFromDatabaseForCurrentLangPair, setGatedCommunitiesFromDatabaseForCurrentLangPair] = useState<GatedCommunitySearchRecord[] | undefined>([])

  useEffect(() => {
    getGCs ()
  }, [langFrom, langTo, gatedCommunities])

  const customExcelRangeFromA1 = (originalRange: string | undefined) => {
    const range = originalRange?.split(":")
    return (range && range.length > 1) ? `A1:${range[1]}` : undefined
  }

  const readExcel = (file: Blob) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(file)

      fileReader.onload = (e) => {
        if(e !== null && e.target !== null){
          const bufferArray = e.target.result
          const workBook = XLSX.read(bufferArray, { type: "buffer", sheetRows: 5 })
          const [workSheetName] = workBook.SheetNames

          if(workSheetName !== undefined){
            const workSheet = workBook.Sheets[workSheetName]

            if(workSheet !== undefined){
              const data = XLSX.utils.sheet_to_json(workSheet, { header: 1, range: customExcelRangeFromA1(workSheet['!ref']) })
              resolve(data)
            }
          }
        }
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })

    promise.then((data: any) => {
      setItems(data.slice(1, data.length > 5 ? 5 : data.length))
      setColumnNames(data[0])
      setColumnNamesSelectOptions((data[0] as Array<string>).map( (item, index) => ({ id: `${index}`, name: item })))
    })
  }

  const resetValues = () => {
    setSelectedFile(null)
    setItems([])
    setColumnNames([])
    setColumnNamesSelectOptions([])
    setLangFrom(undefined)
    setLangTo(undefined)
    setSourceTextIndex(undefined)
    setTargetTextIndex(undefined)
    setCommentColumnsIndexes([])
    setGatedCommunities([])
    setIsDeleteOldBatchSubAssets(false)
  }

  async function saveSubAssets () {
    setIsLoading(true)

    if(selectedFile && langFrom && langTo && sourceTextIndex !== undefined && targetTextIndex !== undefined && taxonomyKeys?.length && gatedCommunities?.length){
      const formData = new FormData()
      formData.append('file', selectedFile)
      formData.append('langFrom', langFrom.id)
      formData.append('langTo', langTo.id)
      formData.append('sourceTextIndex', sourceTextIndex.toString())
      formData.append('targetTextIndex', targetTextIndex.toString())
      formData.append('commentIndexes', commentColumnsIndexes.toString())
      taxonomyKeys.forEach(key => formData.append('taxonomyKey', key))
      formData.append('gatedCommunities', gatedCommunities.map(gc => gc.id).toString())
      formData.append('isDeleteOldBatchSubAssets', isDeleteOldBatchSubAssets.toString())
      try {
        const response = await axios.post(`/asset-manager/subassets/import`, formData, { responseType: 'blob' })
        saveFile(response.data, `import_result_${selectedFile.name}`)
        resetValues()
        if(fileInputRef!==null && fileInputRef.current!==null){
          fileInputRef.current.value = ''
        }
        notify('Successfully saved subAssets', { type: 'success' })
      } catch (error) {
        notify('Something went wrong', { type: 'error' })
      }
    }
    setIsLoading(false)
  }

  async function getGCs () {
    setGatedCommunitiesFromDatabaseForCurrentLangPair(undefined)
    if(langFrom && langTo && gatedCommunities?.length){
      setIsSearching(true)
      const response = await axios.get(`/asset-manager/subassets/gc`, {
        params: {
          sourceLang: langFrom.id,
          targetLang: langTo.id,
          gatedCommunities: gatedCommunities.map(gc => gc.id).toString()
        }
      })
      setGatedCommunitiesFromDatabaseForCurrentLangPair(response.data)
      setIsSearching(false)
    }
  }

  const borderStyle = {
    'border': "1px solid black",
    'border-collapse': "collapse",
    'padding': '3px'
  }

  function showRow (row: string[], index: string | number, isHeadRow = false) {
    const showCell = (idx: number, value: string | undefined) => isHeadRow ? 
      <th key={idx} style={borderStyle}>{value}</th> : 
      <td key={idx} style={borderStyle}>{value}</td>
    const cells = []
    for (let i = 0; i < row.length; i++){ cells.push(showCell(i, row[i])) }
    return <tr style={borderStyle} key={index}>{cells}</tr>
  }
  
  return (
    <Card>
      <Title title="SubAssets/Terms" />
      <CardContent component="div" sx={{ bgcolor: '#FAFAFA', paddingLeft: '4%', paddingRight: '1%' }}>
        <Box className='gray-skin' display={{ md: 'block', lg: 'flex' }} flexDirection='column' gap={GAP} flexWrap='wrap' paddingBottom='30px' paddingTop='50px' alignItems='flex-start'>
          <Box width='100%' display='flex' justifyContent='space-between'>
            <Typography sx={{ font: 'normal normal bold 26px/31px Inter' }}>
              SubAssets / Terms Import
            </Typography>
            <SubassetsListDashboardButton />
          </Box>
          <RoundedPanel padding='5px 0px' >
            <div style={ { marginRight: '150px' } }>
              <input
                ref={fileInputRef}
                type="file"
                accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={(e) => {
                  resetValues()
                  if(e.target.files !== null){
                    const [file] = e.target.files
                    if(file !== undefined){
                      setSelectedFile(file)
                      readExcel(file)
                    }
                  }
                }}
              />

              { items.length>0 && <>
                <table style={borderStyle}>
                  <thead>
                    {showRow(columnNames, 'head', true)}
                  </thead>
                  <tbody>
                    {items.map((row, index) => showRow(row, index))}
                  </tbody>
                </table>

                <Grid container spacing={'10px'}>
                  <Grid item xs={4}>
                    <LanguageAutocomplete 
                      label="Source lang"
                      onChange={newValue => setLangFrom(newValue)}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <CustomSelectInput
                      label="Source text column"
                      choices={columnNamesSelectOptions}
                      alwaysOn
                      onChange={v => setSourceTextIndex(v as unknown as number)}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <LanguageAutocomplete 
                      label="Target lang"
                      onChange={newValue => setLangTo(newValue)}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <CustomSelectInput
                      label="Target text column"
                      choices={columnNamesSelectOptions}
                      alwaysOn
                      onChange={v => setTargetTextIndex(v as unknown as number)}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CustomMultiselect 
                      label="Comment columns (many)"
                      choices={columnNamesSelectOptions}
                      alwaysOn
                      fullWidth
                      onChange={v => setCommentColumnsIndexes(v)}
                    />
                  </Grid>
                  <Grid item xs={6}></Grid>

                  <Grid item xs={5}>
                    <TaxonomyMultiSearch
                      defaultValues={convertTaxonomyKeysToOptions(taxonomyKeys)}
                      defaultOptions={convertTaxonomyKeysToOptions(taxonomyKeys)} 
                      onChange={v => setTaxonomyKeys(v)}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <GatedCommunitySearch 
                      onChange={v => setGatedCommunities(v)}
                    />
                  </Grid>
                </Grid>

                <NormText>
                  <NoSel><b>PREVIEW RESULT FILE: </b></NoSel>
                </NormText>

                <table style={ borderStyle }>
                  <thead>
                    <tr id='head' style={borderStyle}>
                      <th id='0' style={ borderStyle }>subAssetId</th>
                      <th id='1' style={borderStyle}>batchId</th>
                      <th id='2' style={borderStyle}>{translate(langFrom ? langFrom?.name : '')} source text</th>
                      <th id='3' style={borderStyle}>{translate(langTo ? langTo?.name : '')} target text</th>
                      <th id='4' style={borderStyle}>{ columnNames.filter((_, index) => commentColumnsIndexes.includes(`${index}`)).join(';\n') }</th>
                      <th id='5' style={borderStyle}>errors</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                  <tbody>
                    {items.map((row, index) => (
                      <tr style={borderStyle} key={index}>
                        <td id='0' style={borderStyle}>UUID</td>
                        <td id='1' style={borderStyle}>UUID</td>
                        <td id='2' style={borderStyle}>{sourceTextIndex && row[sourceTextIndex]}</td>
                        <td id='3' style={borderStyle}>{targetTextIndex && row[targetTextIndex]}</td>
                        <td id='4' style={borderStyle}>{
                          row.reduce(
                            function (accumulator, currentValue, currentIndex){
                              if(currentValue !== '' && commentColumnsIndexes.includes(`${currentIndex}`)){
                                accumulator.push(`${columnNames[currentIndex]}: ${currentValue}`)
                              }
                              return accumulator
                            },
                          [] as string[]
                          ).join(';\n')
                        }</td>
                        <td id='5' style={borderStyle}></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <br/>
                <NormText>
                  <Checkbox
                    className="action-checkbox"
                    style={{ width: "18px", height: "18px", marginRight: "0px", padding: "0px" }}
                    icon={<></>}
                    checkedIcon={<Done color='action' style={{ width: "17px", height: "17px" }}/>}
                    onChange={event => setIsDeleteOldBatchSubAssets(event.target.checked)}
                    disableRipple={true}
                  />&nbsp;DELETE old batch subassets(terms) for languages pair {langFrom?.id}{'->'}{langTo?.id} and the GCs selected above
                  <br/>
                  {isDeleteOldBatchSubAssets && 
                    <span style={ { color:'red' } }>GCs whose subassets(terms) will also be removed for this language pair: 
                    [{isSearching ? 
                      <b>Searching...</b>:
                      <b>{gatedCommunitiesFromDatabaseForCurrentLangPair?.map(gc => gc.name).join(', ')}</b>
                    }]
                    </span>}
                </NormText>
                <br/>
                <CustomButton
                  onClick={() => saveSubAssets()} 
                  sx={{ padding: '3px 15px', minWidth: 'auto' }}
                  disabled={ isLoading || isSearching || !selectedFile || !langFrom || !langTo || !sourceTextIndex || !targetTextIndex || taxonomyKeys?.length==0 || gatedCommunities?.length==0 }
                >
                Save
                </CustomButton>

              </>}
            </div>
          </RoundedPanel>
        </Box>
      </CardContent>
    </Card>
  )
}
  
export default SubAssets