import React, { useEffect } from 'react'
import { useTranslate, useInput } from 'react-admin'

import { Chip, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps } from '@mui/material'
import { FilterIcon } from 'common/Icons'

import 'styles.scss'

interface Choice {
  id: string,
  name: string,
}

interface CustomArrayInputProps {
  source: string,
  choices: Choice[]
  label: string,
  alwaysOn?: boolean,
  fieldProps?: SelectProps<string[]>,
  onChange?: (value: string[]) => void,
  defaultValue?: string[],
  fullWidth?: boolean,
}

export const OutlinedArrayInput = (props: CustomArrayInputProps) => {
  const { source } = props
  const { field } = useInput({ source: source })

  return <CustomMultiselect {...props} defaultValue={field.value} onChange={value => field.onChange(value)}/>
}

interface CustomMultiselectProps {
  choices: Choice[]
  label: string,
  alwaysOn?: boolean,
  fieldProps?: SelectProps<string[]>,
  onChange?: (value: string[]) => void,
  defaultValue?: string[],
  fullWidth?: boolean
}

export const CustomMultiselect = ({ choices, label, fieldProps, onChange, fullWidth, defaultValue }: CustomMultiselectProps) => {
  const translate = useTranslate()
  const [selectedItems, setSelectedItems] = React.useState<string[]>(defaultValue || [])

  useEffect(() => {
    onChange && onChange(selectedItems)
  }, [selectedItems])
  
  const handleItemSelect = (event: SelectChangeEvent<string[]>) => {
    setSelectedItems(event?.target?.value as string[])
  }

  const chipRemove = (item: string) => {
    const updatedSelectedItems = selectedItems.filter((selectedItem) => selectedItem !== item)
    setSelectedItems(updatedSelectedItems)
  }

  const chipAdd = (item: string) => {
    const updatedSelectedItems = new Set([...selectedItems, item])
    setSelectedItems([...updatedSelectedItems])
  }

  return (<>
    <FormControl className="custom-array-input margin" sx={fullWidth ? { minWidth: "100% !important" } : undefined}>
      <InputLabel id="profile" shrink>{label}</InputLabel>
      <Select
        {...fieldProps}
        multiple
        notched
        label={label}
        value={selectedItems}
        onChange={handleItemSelect}
        renderValue={(selected) => (
          <div>
            {selected.map((item) => (
              <Chip
                key={item}
                className="big-chip"
                label={translate(choices.find(c => c?.id == item)?.name || item)}
                onDelete={() => { chipRemove(item) }}
                deleteIcon={
                  <FilterIcon
                    type="gray-x"
                    onMouseDown={(event) => event.stopPropagation()} />
                }
              />
            ))}
          </div>
        )}
        MenuProps={{
          PaperProps:{
            sx: {
              "min-width": "unset !important",
              ".Mui-selected": {
                display: "none"
              }
            }
          }
        }}
      >
        {choices.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            <Chip
              key={id}
              className="squere-chip"
              label={translate(name)}
              onDelete={() => chipAdd(id)}
              deleteIcon={
                <FilterIcon
                  type="gray-plus" 
                />
              }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </>

  )
}